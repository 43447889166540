const locationInsuranceMapping = {
  all: [],
  california: [
    "aetna",
    "anthem-blue-cross",
    "cigna-evernorth",
    "health-net-mhn",
    "blue-shield-of-ca",
  ],
  "new-york": ["aetna", "cigna-evernorth", "umr-for-mount-sinai"],
  default: ["aetna", "cigna-evernorth"], // for all other states
};

export const getInsuranceOptionByLocation = (location, allInsurances) => {
    const insuranceValuesForLocation =
      locationInsuranceMapping[location] || locationInsuranceMapping["default"];
    return allInsurances.filter(insurance =>
      insuranceValuesForLocation.includes(insurance.value)
    );
  };