import showdown from "showdown";
import { ZD_SCRIPT_ID, ZD_SCRIPT_SRC } from "../lib/hooks/useZendeskScript";
import { stateToName } from "../lib/data/listOfStates";
import { ProviderNode } from "../components/ProvidersList/types";

export const slugify = text => {
  if (!text) return;

  return text
    .toLowerCase()
    .replace("/", " ")
    .replace("-", " ")
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
};

export const getMetadata = (
  cmsMetaData,
  codeOverwriteMetaTitle = "",
  codeOverwriteMetaDescription = "",
  codeOverwriteOgImage = false
) => {
  // Priority Order
  // 1. Overwrite via Meta Tab in CMS
  // 2. Code base meta
  // 3. Generic Default in Code (Overwrite In SEO Component)
  const metaData = {
    meta_title: cmsMetaData?.meta_title?.text || codeOverwriteMetaTitle || "",
    meta_description:
      cmsMetaData?.meta_description || codeOverwriteMetaDescription || "",
  };

  const cmsOgImg = cmsMetaData?.og_image_overwrite?.url;
  if (codeOverwriteOgImage || cmsOgImg) {
    metaData["ogImage"] = cmsOgImg || codeOverwriteOgImage;
  }

  return metaData;
};

export const getOpenGraphImage = pageData => {
  const heroSections = pageData.body.filter(
    slice => slice.slice_type === "section_hero"
  );

  const heroImage = heroSections[0] && heroSections[0].primary.hero_image.url;
  return heroImage || null;
};

export const flattenNode = pageNode => {
  if (!pageNode || !pageNode.uid) {
    return pageNode;
  }
  return {
    uid: pageNode.uid,
    ...pageNode.data,
  };
};

export const flattenSlice = body => {
  return {
    ...body.primary,
    items: body.items,
  };
};

export const isEmpty = input => {
  if (input === null || input === undefined) {
    return true;
  }
  if (typeof input === "string" && input.trim() === "") {
    return true;
  }
  if (input === "<p></p>") {
    return true;
  }
  if (Array.isArray(input) && input.length === 0) {
    return true;
  }
  if (Array.isArray(input) && input.length === 1 && input[0] === undefined) {
    return true;
  }
  if (typeof input === "object" && Object.keys(input).length === 0) {
    return true;
  }
  return false;
};

/*
 * Takes all slices in a page, and makes some adjustments.
 * - Combine consecutive slices of a certain type into one object
 *   for easier passing into component.
 */
export const adjustSlices = pageData => {
  let pageBody = pageData.body;
  let adjPageBody = [];

  if (!pageBody) {
    return adjPageBody;
  }

  let mark = 0;
  let prevType = null;

  pageBody.forEach((slice, i) => {
    let thisType = slice.slice_type;

    // Handle FAQ repeated slices (combine into one object).
    // We may need to do adjustments on other types.
    const combinedTypes = ["faq_topic_section", "cta_block"];

    if (combinedTypes.includes(thisType)) {
      if (mark === 0) {
        adjPageBody[i] = {
          slice_type: thisType,
          combined: [slice],
        };
        mark = i;
      } else if (prevType === thisType && mark) {
        adjPageBody[mark].combined.push(slice);
      }
    } else {
      mark = 0;
      adjPageBody.push(slice);
    }
    prevType = thisType;
  });
  return adjPageBody;
};

export const truncateString = (str, num, suffix) =>
  str.length < num
    ? str
    : `${str.substr(
        0,
        str.substr(0, num - suffix.length).lastIndexOf(" ")
      )}${suffix}`;

export const getSessionStorage = sessionStorageVar => {
  return typeof window !== "undefined"
    ? window.sessionStorage.getItem(sessionStorageVar)
    : null;
};

export const setSessionStorage = (sessionStorageVar, value) => {
  return typeof window !== "undefined"
    ? window.sessionStorage.setItem(sessionStorageVar, value)
    : null;
};

export const combineQueryString = (searchParams, newSearchParams) => {
  let combined = searchParams;
  newSearchParams.forEach((value, key) => {
    combined.set(key, value);
  });
  return combined;
};

/**
 * Used in Provider List Filters to help generate a stringified
 * format of query params to be used in the url
 * @param {[string]: {key: string, uid: string, label: string}} params
 * @returns string
 */
export const stringifyQueries = params => {
  const transformParamString = value => value.split(" ").join("+");

  return Object.keys(params)
    .reduce((acc, item) => {
      if (acc.length === 0 && params[item]) {
        acc.push(`?${item}=${transformParamString(params[item])}`);
      } else if (params[item]) {
        acc.push(`&${item}=${transformParamString(params[item])}`);
      }

      return acc;
    }, [])
    .join("");
};

// Get unique list of objects by key
// https://stackoverflow.com/a/56768137
export function getUniqueListBy(arr, key) {
  return [...new Map(arr.map(item => [item[key], item])).values()];
}

/**
 * Transform html to readable string
 * https://stackoverflow.com/a/49808289
 * @param {string} value
 * @returns string
 */
export const transformHtmlToString = value => {
  let temp;
  if (typeof document !== "undefined") {
    temp = document.createElement("div");
    temp.innerHTML = value;
    return temp.textContent;
  }

  return "";
};

// Load Zendesk Widget Script
export const loadZdScript = () => {
  // Create script
  const script = document.createElement("script");
  script.src = ZD_SCRIPT_SRC;
  script.id = ZD_SCRIPT_ID;
  script.async = true;

  // Add script to document body
  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  };
};

// Check if "test" is found between non-word characters, ie. -test- or [test]
// This is used in CMS to test on development, but not needed in production
export const isTestDoc = val => {
  return /\btest\b/i.test(val);
};

export const mapAirtableProviderStatesToPrismic = airtableProviderEdges => {
  const stateOptions = [
    ...new Set(
      airtableProviderEdges.reduce((res, n) => {
        if (n.node.data.State__for_website_view_) {
          const stateName = stateToName(n.node.data.State__for_website_view_);
          if (stateName) {
            res.push(stateName);
          }
        }
        return res;
      }, [])
    ),
  ];
  stateOptions.sort();
  // TODO: clean this up. currently mirrors prismic data structure.
  const locationStateOptions = {
    edges: stateOptions.map(stateName => {
      return {
        node: {
          uid: slugify(stateName),
          data: {
            active_filter_list: true,
            name: stateName,
          },
        },
      };
    }),
  };
  return locationStateOptions;
};

export const getProviderUid = providerData => {
  if (!providerData?.Display_Name__for_website_) {
    return null;
  }
  const nameUid = decodeURIComponent(
    providerData?.Display_Name__for_website_.toLowerCase()
  );
  const mainCredential = providerData?.Credentials[0];
  return slugify(`${nameUid} ${mainCredential}`);
};

function ensureArray(arr: any) {
  return arr ? arr : [];
}

export const mapAirtableProviderNodesToPrismic = edges => {
  let remappedEdges = [];
  edges.forEach(n => {
    const newNode = mapAirtableProviderNodeToPrismic(n.node);
    if (newNode) {
      remappedEdges.push({ node: newNode });
    }
  });
  return remappedEdges;
};

export const mapAirtableProviderNodeToPrismic = (node: ProviderNode) => {
  if (!node.data?.Display_Name__for_website_) {
    return null;
  }
  if (!node.data.State__for_website_view_) {
    return null;
  }
  const urlUid = getProviderUid(node.data);

  // State, location, and image are all required!
  const stateName = stateToName(node.data.State__for_website_view_);
  const stateAbbreviation = node.data.State__for_website_view_;
  const officeFromAirtable = node.data.Location?.split("-")[1].trim();
  const officeLocations = [
    {
      name: officeFromAirtable,
      uid: slugify(officeFromAirtable),
      filterUid: node.data.Location.trim(),
    },
  ];

  if (!officeLocations[0].name.startsWith("Virtual")) {
    officeLocations.unshift({
      name: "Virtual",
      uid: "virtual",
      filterUid: `${stateAbbreviation} - Virtual`,
    });
  }
  const photoData = {
    relativeUrl: node.data.Edited_Headshot?.localFiles[0].publicURL,
    ...node.data.Edited_Headshot?.localFiles[0].childImageSharp,
    url: `/providers/${urlUid}/photo.jpg`,
    airtableUrl: node.data.Edited_Headshot?.localFiles[0]?.url,
  };

  if (!stateName || !officeFromAirtable || !photoData) {
    return null;
  }

  const rawLanguages = ensureArray(
    node.data.Additional_Language_Capabilities
  ).concat([]);
  rawLanguages?.sort();
  const languages = rawLanguages?.map(l => {
    return { language: { uid: slugify(l), document: { data: { name: l } } } };
  });

  const rawLicenses = ensureArray(node.data.Credentials).concat([]);
  rawLicenses?.sort();
  const licenseGroups = rawLicenses?.map(l => {
    let groupedName,
      groupedKey = null;
    if (l === "LCSW") {
      groupedName = "Licensed Clinical Social Worker (LCSW)";
      groupedKey = "LCSW";
    } else if (l === "LMFT") {
      groupedName = "Licensed Marriage and Family Therapist (LMFT)";
      groupedKey = "LMFT";
    } else if (l === "LMHC" || l === "LPCC" || l === "LPC") {
      groupedName = "Licensed Professional Counselor (LMHC, LPCC, or LPC)";
      groupedKey = "LPC";
    } else if (l === "PsyD" || l === "PhD") {
      groupedName = "Psychologist (PsyD or PhD)";
      groupedKey = "PsyD";
    }
    return {
      license: {
        uid: slugify(groupedKey),
        document: { data: { name: groupedName } },
      },
    };
  });

  const rawSpecialtyTags = ensureArray(node.data.Specialties).concat([]);
  rawSpecialtyTags?.sort();
  const specialtyTags = rawSpecialtyTags?.map(st => {
    let cleanSt = st.split("(")[0].split("*")[0].replace(" / ", "/").trim();
    if (cleanSt === "Pregnancy/Peripartum/Infertility") {
      cleanSt = "Pregnancy/Peripartum";
    }
    return {
      tag: { uid: slugify(cleanSt), document: { data: { name: cleanSt } } },
    };
  });

  const rawModalityTags = ensureArray(node.data.Treatment_Modalities).concat(
    []
  );
  rawModalityTags?.sort();
  const modalityTags = rawModalityTags?.map(mt => {
    let cleanMt = mt.split("*")[0].trim();
    return {
      tag: { uid: slugify(cleanMt), document: { data: { name: cleanMt } } },
    };
  });

  let rawInsurances = [];
  if (Array.isArray(node.data.Insurance_Accepted)) {
    rawInsurances = node.data.Insurance_Accepted;
  } else if (node.data.Insurance_Accepted) {
    rawInsurances = node.data.Insurance_Accepted.split(",");
  }

  //TODO: revist - avoid hardcoding this list
  const insurances = rawInsurances?.map(i => {
    let insuranceName;

    let cleanI = i.trim();

    if (cleanI === "aetna") {
      insuranceName = "Aetna";
    } else if (cleanI === "blue_shield_of_ca") {
      insuranceName = "Blue Shield of CA";
    } else if (
      cleanI === "healthnet" ||
      i === "mhn" ||
      i === "healthnet, mhn"
    ) {
      insuranceName = "Health Net/MHN";
    } else if (cleanI === "cigna") {
      insuranceName = "Cigna/Evernorth";
    } else if (cleanI === "mt_sinai" || i === "umr" || i === "umr, mt_sinai") {
      insuranceName = "UMR for Mount Sinai";
    } else if (cleanI === "anthem") {
      insuranceName = "Anthem Blue Cross";
    }

    return {
      insurance: {
        uid: slugify(insuranceName),
        document: { data: { name: insuranceName } },
      },
    };
  });

  const converter = new showdown.Converter();

  const personalInfoList = [];
  const fields = [
    {
      nodeName: "Why_did_you_become_a_mental_health_professional_",
      header: "Why did you become a mental health professional?",
    },
    {
      nodeName: "What_are_your_interests_outside_of_work_",
      header: "What are your interests outside of work?",
    },
    {
      nodeName:
        "What_is_one_thing_you_do_daily_that_supports_your_mental_and_emotional_well_being_",
      header: "What is one thing you do daily that supports your well-being?",
    },
    {
      nodeName: "What_book_have_you_read_more_than_once_and_why_",
      header: "What book have you read more than once?",
    },
    {
      nodeName: "Favorite_organization_non_profit_and_why_",
      header: "Favorite organization/non-profit?",
    },
    { nodeName: "How_do_you_recharge_", header: "How do you recharge?" },
    {
      nodeName: "Do_you_have_any_pets_and_what_s_your_favorite_pet_story_",
      header: "Do you have any pets?",
    },
  ];

  fields.forEach(field => {
    if (node.data[field.nodeName]) {
      personalInfoList.push({
        header: field.header,
        type: "personal info",
        text_details: {
          html: converter.makeHtml(node.data[field.nodeName]),
        },
      });
    }
  });

  return {
    recordId: node.recordId,
    uid: urlUid,
    url: `/providers/${urlUid}`,
    data: {
      is_active: node.data.Status === "Active",
      name: {
        text: `${
          node.data.Display_Name__for_website_
        }, ${node.data.Credentials.join(", ")}`,
      },
      npi: node.data.NPI_Number,
      licensed_in: stateAbbreviation,
      quote: {
        text: node.data.Quote__for_website_,
      },
      profile_photo: photoData,
      office_locations: officeLocations,
      state_location: stateName,
      role: node.data.Role__for_website_view_,
      job_title: node.data.Job_Title__for_website_view_,
      languages: languages,
      license_groups: licenseGroups,
      license_number: node.data.License_Number,
      insurances: insurances,
      specialty_tags: specialtyTags,
      treatment_modality_tags: modalityTags,
      pronouns: node.data.Pronouns,
      body: [
        {
          slice_type: "provider_tab_alt",
          primary: { tab_name: "Intro" },
          items: [
            {
              header: "My Focus Areas",
              type: "specialty tags",
            },
            {
              header: "My Therapy Style",
              type: "text",
              text_details: {
                html: converter.makeHtml(node.data.Therapy_Style),
              },
            },
            {
              header: "My Approach",
              type: "treatment modality tags",
            },
          ],
        },
        {
          slice_type: "provider_tab_alt",
          primary: { tab_name: "Education" },
          items: [
            {
              header: "Education & Training",
              type: "education",
              text_details: { html: converter.makeHtml(node.data.Education) },
            },
          ],
        },
        {
          slice_type: "provider_tab_alt",
          primary: { tab_name: "Personal Details" },
          items: personalInfoList,
        },
      ],
    },
  };
};
